import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
    <Layout>
        <SEO title="Inicio" />
        <div className="banner-area-three">
            <div className="container-fluid">
                <div className="container-max-2">
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="banner-content-three">
                                <span>Bienvenido</span>
                                <h1>Tecnología de calidad</h1>
                                <p>En Ugni diseñamos, construimos, desplegamos y medimos los resultados de proyectos que involucran hardware & software de manera transversal, ofreciendo servicios especializados de ingeniería en proyectos de I+D+I.</p>
                                <div className="banner-three-btn">
                                    <a href="#" className="explore-btn">
                                        Hablemos
                                        <i className="bx bx-plus"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="banner-img-3">
                <StaticImage
                    src="../images/home-three/home-three-img.png"
                    quality={95}
                    alt="Ugni SAS Logo"
                />
            </div>
            <div className="banner-three-shape">
                <div className="shape1">
                    <StaticImage
                        src="../images/shape/shape16.png"
                        quality={95}
                        alt="Ugni SAS Logo"
                    />
                </div>
                <div className="shape2">
                    <StaticImage
                        src="../images/shape/shape15.png"
                        quality={95}
                        alt="Ugni SAS Logo"
                    />
                </div>
            </div>
        </div>
        <div className="brand-logo-area">
            <div className="container-fluid">
                <div className="container-max">
                    <div className="brand-logo-slider owl-carousel owl-theme">
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/ldc-logo.png" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/jah-logo.png" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/tm-logo.png" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/sda-bog-logo.jpg" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/suma-logo.png" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/innref-logo.png" alt="Images" />
                        </div>
                        <div className="brand-logo-item">
                            <StaticImage src="../images/brand/gmovil-logo.png" alt="Images" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="service-area-three pt-100 pb-70">
            <div className="container">
                <div className="section-title text-center">
                    <span className="sp-after">Nuestros servicios</span>
                    <h2>Desplegamos servicios de TI bajo metodologías ágiles.</h2>
                    <p>
                        Nuestros productos responden a las necesidades y el entorno cambiante de los negocios. Contamos con grupos de Desarrollo a la Medida, Software factory, y mantenimiento de Aplicaciones que están a su disposición.
                    </p>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="service-item-two">
                            <a href="#">
                                <StaticImage src="../images/service/service-icon10.png" alt="assets" />
                            </a>
                            <h3><a href="#">Desarrollo de software</a></h3>
                            <p>Creamos software para múltiples plataformas usando tecnologías y metodologías que escalan y cumplen los requerimientos de nuestros clientes.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="service-item-two">
                            <a href="#">
                                <StaticImage src="../images/service/service-icon11.png" alt="assets" />
                            </a>
                            <h3><a href="#">IoT</a></h3>
                            <p>Desarrollamos soluciones que involucran sensórica, dispositivos electrónicos y redes de largo alcance para cumplir los objetivos de proyectos IoT.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 col-md-6 offset-sm-3 offset-md-3 offset-lg-0">
                        <div className="service-item-two">
                            <a href="#">
                                <StaticImage src="../images/service/service-icon12.png" alt="assets" />
                            </a>
                            <h3><a href="#">Inteligencia artificial</a></h3>
                            <p>Innovamos con soluciones que involucran las tecnologías de IA que están revolucionado las industrias en el mundo.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="about-area-two pb-70">
            <div className="container-fluid">
                <div className="container-max">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-img-2">
                                <StaticImage src="../images/about/about-img3.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-content-two">
                                <div className="section-title">
                                    <span className="sp-before sp-after">Sobre Ugni</span>
                                    <h2> </h2>
                                </div>
                                <h3>Con más de 11 años de experiencia en tecnología y siempre adaptándose a nuevos retos, Ugni apunta a ser un líder de la ingeniería de software y construcción de dispositivos electrónicos en Latinoamérica.</h3>
                                <p>
                                    Utilizamos procesos ágiles para prestar servicios de TI (desarrollo a la medida, fábrica de software, despliegue de soluciones electrónicas) que resuelvan sus retos de negocio grandes y pequeños.
                                    Desarrollamos productos y servicios de alta calidad aplicando procesos y normas estrictas. Construimos arquitecturas complejas y desarrollamos nuestros productos en languajes de última tecnologías como React Native & Flutter (movil), React, Angular y Vue (web), .NET, NodeJS, Firebase, LoRa, SigFox, NB-IoT y LTE.
                                </p>
                                <div className="about-btn-two">
                                    <a href="/services/" className="about-more-btn">
                                        Servicios
                                        <i className="bx bx-plus"></i>
                                    </a>
                                    <a href="/contact-us/" className="contact-us-btn">
                                        Hablemos
                                        <i className="bx bx-plus"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="schedule-area">
            <div className="container-fluid">
                <div className="schedule-width ptb-100">
                    <div className="schedule-content">
                        <h2>
                            Agenda una cita con una persona del equipo
                        </h2>
                        <a href="/contact-us/" className="default-btn-two">
                            y revisaremos tu caso
                            <i className="bx bx-plus"></i>
                        </a>
                        <div className="schedule-content-shape">
                            <div className="shape1">
                                <StaticImage src="../images/shape/shape15.png" alt="Images" />
                            </div>
                            <div className="shape2">
                                <StaticImage src="../images/shape/shape16.png" alt="Images" />
                            </div>
                        </div>
                    </div>
                    <div className="schedule-shape">
                        <StaticImage src="../images/shape/schedule-shape.png" alt="Images" />
                    </div>
                </div>
            </div>
        </div>
        <div className="serve-area pt-100 pb-70">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="serve-content">
                            <div className="section-title">
                                <span className="sp-before sp-after">¿Porqué estamos aquí?</span>
                                <h2 className="h2-color2">La tecnología sigue cambiando al mundo</h2>
                                <p>Estamos acá para proveer soluciones tecnológicas a un amplio sector dónde hemos demostrado con éxito nuestra excelencia </p>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-sm-6">
                                    <div className="serve-list">
                                        <i className="bx bx-check"></i>
                                        <span>01</span>
                                        <p>Sensórica y Sistemas inteligentes de transporte (ITS)</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="serve-list">
                                        <i className="bx bx-check"></i>
                                        <span>02</span>
                                        <p>Soluciones gobierno digital</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="serve-list">
                                        <i className="bx bx-check"></i>
                                        <span>03</span>
                                        <p>Inteligencia artificial</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="serve-list">
                                        <i className="bx bx-check"></i>
                                        <span>04</span>
                                        <p>Internet de las cosas</p>
                                    </div>
                                </div>
                            </div>
                            <p>Permitanos ser su aliado en la transformación digital de su empresa o entidad.</p>
                            <a href="/contact-us/" className="default-btn-two">
                                Hablemos
                                <i className="bx bx-plus"></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="serve-img">
                            <StaticImage src="../images/serve-img.png" alt="Images" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="courses-area pb-70" id="experience">
            <div className="container">
                <div className="section-title text-center">
                    <span className="sp-after">Nuestra experiencia</span>
                    <h2 className="h2-color2">Últimos proyectos</h2>
                    <p> </p>
                </div>
                <div className="row pt-45">
                    <div className="col-lg-6">
                        <div className="courses-card">
                            <div className="images">
                                <a href="#">
                                    <StaticImage src="../images/courses/courses-img1.png" alt="Images" />
                                </a>
                                <div className="rate"><a href="#">Hecho</a></div>
                            </div>
                            <div className="instructor">
                                {/*  <StaticImage src="../images/courses/courses-instructor1.png" alt="Images" />
                                <h3 className="name">
                                    <a href="#">David McLean</a>
                                </h3> */}
                            </div>
                            <h3>
                                <a href="#">
                                    Sistema multiplataforma vending
                                </a>
                            </h3>
                            <p>Computer vision, aplicaciones móviles e <br /> interfaz web administrativa
                            </p>
                            <ul>
                                <li>
                                    <i className="flaticon-phone"></i>
                                Mobile, AI & web
                                </li>
                                <li>
                                    <i className="flaticon-clock"></i>
                                10 meses
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="courses-card">
                            <div className="images">
                                <a href="#">
                                    <StaticImage src="../images/courses/courses-img2.png" alt="Images" />
                                </a>
                                <div className="rate"> <a href="#">Continua</a></div>
                            </div>
                            <div className="instructor">
                                {/* <StaticImage src="../images/courses/courses-instructor2.png" alt="Images" />
                                <h3 className="name">
                                    <a href="#">Jems ward</a>
                                </h3> */}
                            </div>
                            <h3><a href="#"> Soporte para la seguridad en sistema articulado de transporte</a></h3>
                            <p>Desarrollo móvil y web que permite el monitoreo <br /> del cumplimiento de normas en estaciones de transmilenio
                            </p>
                            <ul>
                                <li>
                                    <i className="flaticon-phone"></i>
                                Mobile & Web
                                </li>
                                <li>
                                    <i className="flaticon-clock"></i>
                                2 años
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="courses-card">
                            <div className="images">
                                <a href="#">
                                    <StaticImage src="../images/courses/courses-img3.png" alt="Images" />
                                </a>
                                <div className="rate"> <a href="#">Hecho</a></div>
                            </div>
                            <div className="instructor">
                                {/* <StaticImage src="../images/courses/courses-instructor3.png" alt="Images" />
                                <h3 className="name"><a href="#">Juhan Luis</a></h3> */}
                            </div>
                            <h3><a href="#">Sensado de peso para buses del SITP</a></h3>
                            <p>Desarrollo de un dispositivo único en Colombia para el monitoreo de carga de los buses del SITP.
                            </p>
                            <ul>
                                <li>
                                    <i className="flaticon-phone"></i>
                                    IoT & Web
                                </li>
                                <li>
                                    <i className="flaticon-clock"></i>
                                6 meses
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="courses-card">
                            <div className="images">
                                <a href="#">
                                    <StaticImage src="../images/courses/courses-img4.png" alt="Images" />
                                </a>
                                <div className="rate"> <a href="#">Hecho</a></div>
                            </div>
                            <div className="instructor">
                                {/*  <StaticImage src="../images/courses/courses-instructor4.png" alt="Images" />
                                <h3 className="name">
                                    <a href="#"> Miron Don</a>
                                </h3> */}
                            </div>
                            <h3><a href="#">Track bovino</a></h3>
                            <p>Sistema que previene el robo de ganado usando redes LPWan.
                            </p>
                            <ul>
                                <li>
                                    <i className="flaticon-phone"></i>
                                IoT
                                </li>
                                <li>
                                    <i className="flaticon-clock"></i>
                                8 meses
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="testimonial-area-two pb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="testimonial-content">
                            <div className="testimonial-content1">
                                <div className="testimonial-text">
                                    <h3>150+</h3>
                                    <span>proyectos en <br /> el mercado</span>
                                </div>
                            </div>
                            <div className="testimonial-content2">
                                <div className="testimonial-text">
                                    <h3>10,000+</h3>
                                    <span>stakeholders</span>
                                </div>
                            </div>
                            <div className="testimonial-content3">
                                <div className="testimonial-text">
                                    <h3>3+</h3>
                                    <span>países</span>
                                </div>
                            </div>
                            <div className="testimonial-content4">
                                <div className="testimonial-text">
                                    <h3>125+</h3>
                                    <span>proyectos</span>
                                </div>
                            </div>
                            <div className="testimonial-shape1">
                                <StaticImage src="../images/shape/shape15.png" alt="Images" />
                            </div>
                            <div className="testimonial-shape2">
                                <StaticImage src="../images/shape/shape16.png" alt="Images" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="testimonial-slider-area">
                            <div className="testimonial-title-2">
                                <h2>Nuestra experiencia</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="blog-area blog-bg2 pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="blog-title">
                            <div className="section-title">
                                <span className="sp-after">Blog</span>
                                <h2 className="h2-color-2">Publicaciones recientes de nuestro equipo</h2>
                                <p>
                                    Tutoriales, pensamientos y experimentos con tecnologías recientes por parte del equipo de Ugni
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="blog-btn">
                            <a href="http://blog.ugni.io" className="default-btn-two">
                                Ir al blog
                                <i className="bx bx-plus"></i>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="blog-card">
                            <a href="blog-details.html">
                                <StaticImage src="../images/blog/blog4.png" alt="Images" />
                            </a>
                            <div className="content">
                                <ul>
                                    <li>
                                        <i className="bx bx-time-five"></i>
                                    23 de Marzo, 2021
                                    </li>
                                    <li>
                                        <i className="bx bx-purchase-tag-alt"></i>
                                        <a href="#">Mobile</a>
                                    </li>
                                </ul>
                                <h3><a href="blog-details.html" className="title-color">Actualización de aplicaciones en Flutter</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="blog-card">
                            <a href="blog-details.html">
                                <StaticImage src="../images/blog/blog5.png" alt="Images" />
                            </a>
                            <div className="content">
                                <ul>
                                    <li>
                                        <i className="bx bx-time-five"></i>
                                        23 de Marzo de 2020
                                    </li>
                                    <li>
                                        <i className="bx bx-purchase-tag-alt"></i>
                                        <a href="#">Mobile</a>
                                    </li>
                                </ul>
                                <h3><a href="blog-details.html" className="title-color">Desarrollo de videojuegos en 2D en Flutter</a></h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                        <div className="blog-card">
                            <a href="blog-details.html">
                                <StaticImage src="../images/blog/blog6.png" alt="Images" />
                            </a>
                            <div className="content">
                                <ul>
                                    <li>
                                        <i className="bx bx-time-five"></i>
                                    23 de Marzo de 2020
                                    </li>
                                    <li>
                                        <i className="bx bx-purchase-tag-alt"></i>
                                        <a href="#">AI</a>
                                    </li>
                                </ul>
                                <h3><a href="blog-details.html" className="title-color">Redes neuronales, de la forma sencilla</a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="project-area">
            <div className="container">
                <div className="project-content">
                    <h2>Vamos a darle valor a tu proyecto</h2>
                    <a href="/contact-us/" className="project-btn">Contacto <i className="bx bx-plus"></i></a>
                </div>
            </div>
            <div className="project-shape">
                <div className="shape1">
                    <StaticImage src="../images/shape/shape17.png" alt="" />
                </div>
                <div className="shape2">
                    <StaticImage src="../images/shape/shape18.png" alt="" />
                </div>
            </div>
        </div>
    </Layout>
)

export default IndexPage
